import React from "react"

import Layout from "../components/layout"
import Footer from "@components/footers/dark";
import MenuBar from "@components/menuBar/MenuBar";
import SEO from "../components/seo"
import img from "@images/404.png";

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <MenuBar/>
    <Layout>
      <div className="py-16">
        <p className="text-center px-8 text-4xl text-extrabold">Unfortunately this Page has been declined.</p>
        <img className="grid place-content-center align-middle mx-auto my-8 h-96" src={img} alt="Page Not Found Image" />
        <p className="text-center px-8 text-4xl text-extrabold">Go back and try again.</p>
      </div>
    </Layout>
    <Footer/>
  </>
)

export default NotFoundPage
